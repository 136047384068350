const initialVuexStore = {
    state: {
        isLoading: false,
        showErrorModal: false,
        showMobileMenu: false,
        noLastPage: false,
        storePayload: {
            Performance: {
                payload: {},
                advancedPayload: {}
            }
        },
        errorMessage: '',
        errorDetail: '',
        errorHandler: null,
        getTokenTime: '',
        tokenExpireTime: '',
        token: '',
        refreshToken: '',
        userInfo: null,
        lastPagePath: '',
        accounts: [],
        titleTypes: [],
        isSectionHidden: true,
        tableDeep: 0,
        normalFilter: {},
        advancedFilter: {},
        noticeCount: 0,
        isShowModalForGa: false, //GA事件判斷觸發點是否是在modal內
        isReloadTips: false,
        detailCheckedCount: {}, //保單明細完成通知和保單列表通知數同步
        detailEditedList: {}, //保單明細完成通知和保單列表通知數同步
        webHeaderHeight: 0,
        permissions: {}, //權限允許清單，權限項目請對照"bms權限管理共同文件"google試算表 d
        permissionRouteName: '', //在APP.vue取得權限允許的routeName
        lastUpdateHomeApiTime: '', //Home首頁中API最後更新時間
        lastUpdateHeaderApiTime: '', //Header Bar中API最後更新時間
        isShowLicenseInfo: false,
        isShowQuickLink: false,
        //dashboard API data(value 如為 null:讀取失敗)
        dashboardLicense: {}, //證照資訊
        dashboardCompanyContests: [], //公司競賽
        dashboardCustomerAge: {}, //近期增歲
        dashboardPersonalPerformance: {}, //業績圖表-個人
        dashboardOrgPerformance: {}, //業績圖表-組織
        dashboardPopularProductions: {}, //十大暢銷商品
        dashboardOrganizationManpower: {}, //組織人力
        keepAliveIncludes: [],
        isSectionClicked: false,
        cfRecordDate: null,
        rtRecordDate: null,
        supplierSyncCardCustomerIds: []
    },
    getters: {
        isLogin: (state) => {
            return state.token && state.userInfo !== null
        },
        isAdmin: (state) => {
            return state.userInfo && !state.userInfo.isSales
        },
        idToken: (state) => {
            return state.id_token
        },
        token: (state) => {
            return state.token
        },
        serviceToken: (state) => {
            return state.service_token
        },
        refreshToken: (state) => {
            return state.refreshToken
        },
        hasLeaderBgCodes: (state) => {
            return state.accounts?.some((account) => {
                return account.leaderBgCodes.length > 0
            })
        },
        isTitleCAup: (state) => {
            return state.accounts
                .map((item) => {
                    return item.tLevelName
                })
                .some((item) => {
                    return item === 'BM' || item === 'CM' || item === 'DM'
                })
        }
    },
    actions: {},
    mutations: {
        setHistoryPayload(state, payload) {
            const routerName = payload.routerName
            const keys = Object.keys(state.storePayload[routerName])

            if (payload.payload) {
                state.storePayload[routerName] = payload.payload
            } else {
                keys.forEach((key) => {
                    state.storePayload[routerName][key] = {}
                })
            }
        },
        errorHandler: function (state) {
            if (typeof state.errorHandler === 'function') state.errorHandler()
            state.errorHandler = null
        },
        set: (state, obj) => {
            const keys = Object.keys(obj)
            keys.forEach((key) => (state[key] = obj[key]))
        }
    },
    modules: {}
}

const ssoConfig = {
    sso_uri: process.env.VUE_APP_SSO_URI,
    sso_domain: process.env.VUE_APP_SSO_DOMAIN,
    sso: process.env.VUE_APP_SSO_DOMAIN + process.env.VUE_APP_SSO_PARAM
}

export { initialVuexStore, ssoConfig }
