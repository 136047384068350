<template>
    <div>
        <ViewTitle title="Sample">
            <template v-slot:buttons>
                <div class="flex">
                    <Button
                        buttonWord="開啟Modal"
                        buttonStyle="hollow"
                        @click="isShowModal = true"
                    />
                    <Toolbox toolName="表格工具">
                        <template v-slot:tools>
                            <div>
                                <div class="icon download-excel-icon" />
                                <div>下載Excel</div>
                            </div>
                        </template>
                    </Toolbox>
                </div>
            </template>
        </ViewTitle>
        <div class="component-container">
            <Button
                buttonWord="套用"
                buttonStyle="blue"
                iconClass="big-icon search-icon"
                @click="isShowSuccessModal = true"
            />
            <Button buttonWord="設定" buttonStyle="hollow" />
            <Button buttonWord="下載" buttonStyle="blue-hollow" />
            <Button buttonWord="取消" buttonStyle="grey" />
            <Button buttonWord="刪除" buttonStyle="red" />
            <Button
                buttonWord="隱藏"
                buttonStyle="dashed-border"
                @click="throwError"
            />
        </div>
        <div class="component-container">
            <Button
                buttonWord="套用"
                buttonStyle="blue"
                iconClass="big-icon search-icon"
                :disabled="true"
            />
            <Button buttonWord="設定" buttonStyle="hollow" :disabled="true" />
            <Button
                buttonWord="下載"
                buttonStyle="blue-hollow"
                :disabled="true"
            />
            <Button buttonWord="取消" buttonStyle="grey" :disabled="true" />
            <Button buttonWord="刪除" buttonStyle="red" :disabled="true" />
            <Button
                buttonWord="隱藏"
                buttonStyle="dashed-border"
                :disabled="true"
            />
        </div>
        <div class="component-container">
            <Button
                buttonWord="small-button"
                buttonStyle="blue"
                buttonSize="small"
                iconClass="big-icon search-icon"
            />
            <Button
                buttonWord="default-button"
                buttonStyle="blue"
                buttonSize="default"
                iconClass="big-icon search-icon"
            />
            <Button
                buttonWord="large-button"
                buttonStyle="blue"
                buttonSize="large"
                iconClass="big-icon search-icon"
            />
            <Button
                buttonWord="set width... 350"
                buttonStyle="blue"
                buttonSize="default"
                iconClass="big-icon search-icon"
                :width="350"
                @click="test"
            />
        </div>
        <div class="component-container white-background">
            <Textbox
                title="起始日"
                name="startDate"
                type="date"
                min="today"
                v-model="inputStartDate"
            />
            <Textbox
                title="生日"
                name="birthday"
                type="date"
                max="today"
                v-model="inputBirthday"
            />
            <Textbox
                title="預算"
                name="budget"
                type="number"
                :placeholder="'請輸入預算...'"
                :required="true"
                :isError="true"
                :errorMessage="'輸入密碼錯誤！'"
                v-model="inputBudget"
            />
            <Textbox
                title="姓名"
                name="name"
                type="text"
                :placeholder="'請輸入姓名...'"
                :required="true"
                v-model="inputName"
            />
            <Textbox
                title="信箱"
                name="email"
                type="email"
                :placeholder="'請輸入信箱...'"
                :required="true"
                v-model="inputEmail"
            />
            <Textbox
                title="密碼"
                name="password"
                type="password"
                :placeholder="'請輸入密碼...'"
                :required="true"
                v-model="inputPassword"
            />
        </div>
        <div class="component-container white-background">
            <Textbox
                title="起始日"
                name="startDate"
                type="date"
                min="today"
                :disabled="true"
                v-model="inputStartDate"
            />
            <Textbox
                title="生日"
                name="birthday"
                type="date"
                max="today"
                :isError="true"
                :errorMessage="'日期選擇錯誤！'"
                v-model="inputBirthday"
            />
            <Textbox
                title="禁用"
                name="disabled"
                type="number"
                :placeholder="'disabled...'"
                :required="true"
                :disabled="true"
                v-model="inputDisabled"
            />
            <Textbox
                title="錯誤"
                name="error"
                type="password"
                :placeholder="'error...'"
                :required="true"
                :isError="true"
                :errorMessage="'輸入密碼錯誤！'"
                v-model="inputError"
            />
            <Textbox
                title="信箱"
                name="width"
                type="email"
                :placeholder="'set width... 350'"
                :required="true"
                :width="350"
                v-model="inputWidth"
            />
            <Textbox
                title="信箱"
                name="percentWidth"
                type="email"
                :placeholder="'set width... 100%'"
                :required="true"
                :width="'100%'"
                v-model="inputPercentWidth"
            />
        </div>
        <div class="component-container white-background">
            <Dropdown
                title="基本選項"
                name="dropdownSample1"
                :options="dropdownSample"
                v-model="dropdownValue1"
            />
            <Dropdown
                title="全部選項參數"
                name="dropdownSample2"
                :placeholder="'placeholder'"
                :required="true"
                :width="'30%'"
                :value="'value1'"
                :options="dropdownSample"
                v-model="dropdownValue2"
            />
            <Dropdown
                title="被禁用選項"
                name="dropdownSample3"
                :disabled="true"
                :options="dropdownSample"
                v-model="dropdownValue3"
            />
        </div>
        <div class="component-container white-background">
            <Checkbox
                name="checkboxSampleAll"
                label="全選"
                value="all"
                v-model="checkboxValueAll"
            />
            <Checkbox
                v-for="(checkbox, index) in checkboxSample"
                name="checkboxSample"
                :key="index"
                :label="checkbox.label"
                :value="checkbox.value"
                v-model="checkboxValue"
            />
        </div>
        <div class="component-container"></div>
        <div class="component-container">
            <Step :steps="stepSample" />
        </div>
    </div>
    <Modal v-model="isShowModal" title="範例Modal">
        <template v-slot:modal-content>
            <span>這是範例內容唷</span>
        </template>
        <template v-slot:modal-bottom>
            <Button
                buttonWord="我知道了"
                buttonStyle="blue"
                @click="isShowModal = false"
            />
        </template>
    </Modal>
    <SuccessModal v-model="isShowSuccessModal" message="套用成功" />
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue'
import Button from '@/components/Button.vue'
import Textbox from '@/components/Textbox.vue'
import Checkbox from '@/components/Checkbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import Modal from '@/components/Modal.vue'
import Step from '@/components/Step.vue'
import Toolbox from '@/components/Toolbox.vue'
import SuccessModal from '@/containers/SuccessModal.vue'

export default {
    name: 'Sample',
    components: {
        ViewTitle,
        Button,
        Textbox,
        Checkbox,
        Dropdown,
        Modal,
        Step,
        Toolbox,
        SuccessModal
    },
    methods: {
        test: function () {
            // eslint-disable-next-line
            console.log(this.checkboxValue)
        },
        setEachOptionChecked: function (isChecked) {
            const values = this.checkboxSample.map((checkbox) => checkbox.value)
            values.forEach((value) => {
                this.checkboxValue[value] = isChecked
            })
        },
        throwError: function () {
            throw new Error('Test Sentry!')
        }
    },
    computed: {
        checkboxValueAll: {
            get() {
                const values = this.checkboxSample.map(
                    (checkbox) => checkbox.value
                )
                const shouldChecked =
                    values.length > 0 &&
                    values.every((value) => this.checkboxValue[value])
                return { all: shouldChecked }
            },
            set: function (val) {
                this.setEachOptionChecked(val.all)
            }
        }
    },
    data() {
        return {
            isShowModal: false,
            isShowSuccessModal: false,
            inputStartDate: '',
            inputBirthday: '',
            inputBudget: '',
            inputName: '',
            inputEmail: '',
            inputPassword: '',
            inputDisabled: '',
            inputError: '',
            inputWidth: '',
            inputPercentWidth: '',
            dropdownValue1: '',
            dropdownValue2: 'value1',
            dropdownValue3: '',
            checkboxValue: {},
            dropdownSample: [
                {
                    label: '選項1',
                    value: 'value1'
                },
                {
                    label: '選項2',
                    value: 'value2'
                }
            ],
            checkboxSample: [
                {
                    label: '王大明',
                    value: 'A123456789'
                },
                {
                    label: '林小磊',
                    value: 'F123456789'
                }
            ],
            tableSample: {
                columns: {
                    data1: {
                        label: '標題1',
                        sortEnabled: false,
                        position: 'left'
                    },
                    data2: {
                        label: '標題2',
                        sortEnabled: false,
                        position: 'left'
                    },
                    data3: {
                        label: '標題3',
                        sortEnabled: true,
                        position: 'left'
                    },
                    data4: {
                        label: '標題4',
                        sortEnabled: true,
                        position: 'right'
                    },
                    data5: {
                        label: 'buttons',
                        sortEnabled: false,
                        position: 'right'
                    }
                },
                rows: [
                    {
                        data1: '資料1A',
                        data2: '資料1B',
                        data3: '資料1C',
                        data4: 1120,
                        data5: {
                            html:
                                '<button class="small-icon pencil-edit-icon"></button>'
                        }
                    },
                    {
                        data1: '資料2A',
                        data2: '資料2B',
                        data3: '資料3C',
                        data4: 2000,
                        data5: {
                            html:
                                '<button class="small-icon pencil-edit-icon"></button>'
                        }
                    },
                    {
                        data1: '資料3A',
                        data2: '資料3B',
                        data3: '資料2C',
                        data4: 10000,
                        data5: {
                            html:
                                '<button class="small-icon pencil-edit-icon"></button>'
                        }
                    }
                ]
            },
            stepSample: {
                current: 2,
                labels: ['未開帳', '已開帳', '已開放查詢']
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 30px 0;
    padding: 20px;
    box-sizing: border-box;
    .input-container {
        margin-right: 15px;
    }
}
.white-background {
    background-color: $primary-white;
}
</style>
