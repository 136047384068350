import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import common from './assets/javascripts/common'
import VueGtag from 'vue-gtag-next'

const app = createApp(App)
    .use(store)
    .use(router)
    .use(common)
    .use(VueGtag, {
        property: [
            {
                id: process.env.VUE_APP_GA4_CODE
            }
        ]
        // isEnabled: process.env.VUE_APP_ENV !== 'production'
    })

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        'localhost',
        'bms.leishan.app',
        'bms-test.finext.app',
        'bms-test.leishan.app.local',
        'www.google-analytics.com'
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV
})

app.config.unwrapInjectedRef = true
app.mount('#app')
