<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            class="filterModal"
        >
            <template v-slot:filters>
                <Dropdown
                    name="year"
                    :required="true"
                    :title="filterTitles.year"
                    :options="filterOptions.year"
                    v-model="filterPayload.year"
                />
                <Dropdown
                    name="contestId"
                    :required="true"
                    :title="filterTitles.contestId"
                    :options="filterOptions.contestId"
                    v-model="filterPayload.contestId"
                />
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                    :isShowCeo="hasOrgOption"
                />
                <Dropdown
                    name="dataType"
                    :required="true"
                    :title="filterTitles.dataType"
                    :options="filterOptions.dataType"
                    v-model="filterPayload.dataType"
                />
                <Dropdown
                    name="relationMode"
                    :disabled="!hasOrgOption"
                    :required="hasOrgOption"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { contestFilterTitles } from '@/assets/javascripts/report/setting'
import { getReportContestCodesAPI } from '@/assets/javascripts/api'

export default {
    name: 'ContestFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp',
        'update:contestInfo'
    ],
    components: {
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: '',
                    bgCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        contestInfo: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)

                const {
                    contestId,
                    contestName,
                    scoreTitle,
                    startDate,
                    endDate
                } = this.selectedContest

                this.$emit('update:contestInfo', {
                    contestId,
                    contestName,
                    scoreTitle,
                    startDate,
                    endDate
                })
            }

            this.hasCrossFilter = false
        },
        resetFilter: function () {
            // includes titleType, manCode(payload), role, bgCode(payload)
            //須先知道contestId才能取得後續選項的值，故分開進行reset設定
            this.filterPayload.year = this.filterOptions.year[0]?.value ?? ''
            this.filterPayload.contestId =
                this.filterOptions.contestId[0]?.value ?? ''

            this.filterPayload.relationMode =
                this.filterOptions.relationMode[0]?.value ?? ''

            this.filterPayload.dataType =
                this.filterOptions.dataType[0]?.value ?? ''

            this.$refs.accountRoleFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getReportContestCodesAPI()
                this.dropdownOptions = this.$transformInputType(res.data)
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        selectedContest: function () {
            return this.dropdownOptions.contests?.find((contest) => {
                return contest.contestId === this.filterPayload.contestId
            })
        },
        allowRelationMode: function () {
            return (
                this.selectedContest?.relationModeOptions
                    ?.find((account) => {
                        return (
                            account.titleType.toString() ===
                            this.filterPayload.titleType
                        )
                    })
                    ?.relationModes?.filter((relationMode) => {
                        if (this.filterPayload.role === 'ceo') {
                            return relationMode !== 'self'
                        } else {
                            return true
                        }
                    }) ?? []
            )
        },
        filterOptions: function () {
            const yearOptions = []
            this.dropdownOptions.contests?.forEach((data) => {
                const year = data.contestYear
                if (!yearOptions.includes(year)) yearOptions.push(year)
            })
            return {
                contestId: this.dropdownOptions.contests
                    ?.filter((data) => {
                        return data.contestYear === this.filterPayload.year
                    })
                    .map((data) => {
                        return {
                            label: data.contestName,
                            value: data.contestId
                        }
                    }),
                relationMode: this.dropdownOptions.relationModeList
                    ?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    })
                    .filter((data) => {
                        return this.allowRelationMode.includes(data.value)
                    }),
                dataType: this.dropdownOptions.dataTypeList
                    ?.filter((data) => {
                        return this.selectedContest?.dataTypeOptions.includes(
                            data.key
                        )
                    })
                    .map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    }),
                year: yearOptions.map((data) => {
                    return {
                        label: data,
                        value: data
                    }
                })
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.role': function () {
            if (!this.hasCrossFilter) {
                this.filterPayload.relationMode =
                    this.filterOptions.relationMode &&
                    this.filterOptions.relationMode[0].value
            }
        },
        'selectedContest.hasOrgMode': function (mode) {
            if (mode === false) {
                this.hasOrgOption = false
            } else {
                this.hasOrgOption = true
            }
        },
        'filterPayload.dataType': function (val) {
            if (val === '') {
                this.filterPayload.dataType = this.filterOptions.dataType[0]?.value
            }
        },
        'filterPayload.year': function (_nVal, oVal) {
            if (!oVal) return
            this.filterPayload.contestId =
                this.filterOptions.contestId[0]?.value ?? ''
        }
    },
    data() {
        return {
            filterTitles: {},
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            dropdownOptions: {},
            hasCrossFilter: this.$hasCrossFilter(),
            hasOrgOption: true
        }
    },
    async mounted() {
        this.$nextTick(() => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...contestFilterTitles,
                _seq: [...accountRoleTitles._seq, ...contestFilterTitles._seq]
            }
        })
        await this.getDropdownOptions()
        await this.resetFilter()
        if (this.hasCrossFilter) await this.getFilterData()
    }
}
</script>
