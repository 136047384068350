<template>
    <div>
        <Modal
            title="保險公司最新資訊"
            v-model="isShowModal"
            class="sync-modal"
        >
            <template v-slot:modal-title>
                <div class="info-wrapper">
                    <div class="info-container">
                        <div class="info">
                            <div>
                                <span class="title">保險公司:</span>
                                {{ supplier }}
                            </div>
                            <div>
                                <span class="title">保單號碼:</span> {{ insNo }}
                            </div>
                        </div>
                        <div class="info">
                            <div>
                                <span class="title">被保人:</span>
                                {{ customerName.insured ?? '-' }}
                            </div>
                            <div>
                                <span class="title">要保人:</span>
                                {{ customerName.payer ?? '-' }}
                            </div>
                        </div>
                    </div>
                    <Dropdown
                        title=""
                        name="SyncItem"
                        :options="selectList"
                        :required="true"
                        :disabled="isLoading ? true : false"
                        v-model="docName"
                    />
                </div>
            </template>
            <template v-slot:modal-content>
                <SupplierSyncCard
                    :insNo="insNo"
                    :supplierCode="supplierCode"
                    :docName="docName"
                    :customerId="customerId"
                    :route="page"
                    :isShowModal="modelValue"
                    v-model="isLoading"
                    class="syncCard"
                />
            </template>
            <template v-slot:modal-bottom>
                <div v-if="isShowScroll && !isLoading" class="more">
                    <p v-if="!isOnBottom">滑動看更多內容<span>↓</span></p>
                    <p v-else :class="{ disabled: isOnBottom }">
                        已到達內容底部
                    </p>
                </div>
                <Button
                    buttonWord="關閉"
                    buttonStyle="blue"
                    @click="closeModal"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import _ from 'lodash'
import Button from '@/components/Button.vue'
import Dropdown from '@/components/Dropdown.vue'
import Modal from '@/components/Modal.vue'
import SupplierSyncCard from '@/containers/tia/SupplierSyncCard.vue'
import { syncOptions, config } from '@/assets/javascripts/tia/supplierSync'
import ElementResizeDetectorMaker from 'element-resize-detector'

export default {
    name: 'SupplierSyncModal',
    emits: ['update:modelValue'],
    components: {
        Button,
        Dropdown,
        Modal,
        SupplierSyncCard
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        insNo: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        supplierCode: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        supplier: {
            type: String,
            default: ''
        },
        customerId: {
            type: [String, Array],
            default: function () {
                return null
            }
        },
        customerName: {
            type: Object,
            default: function () {
                return {}
            }
        },
        page: {
            type: String,
            default: ''
        },
        dataStatus: {
            type: Object,
            default: function () {
                return null
            }
        }
    },
    methods: {
        closeModal: function () {
            this.$setGaEvent('closeModal', 'click-Button', {
                isModal: true
            }) //GA
            this.isShowModal = false
        },
        setOnBottom: function () {
            this.$nextTick(() => {
                const container = document.querySelector(
                    '.sync-modal .modal-area'
                )
                const areaHeight = container.offsetHeight ?? 0
                const titleHeight =
                    document.querySelector('.sync-modal .modal-title')
                        .offsetHeight ?? 0
                const bottomHeight =
                    document.querySelector('.sync-modal .modal-bottom')
                        .offsetHeight ?? 0

                const content = document.querySelector(
                    '.sync-modal .modal-content'
                )
                const contentHeight = `${
                    areaHeight - titleHeight - bottomHeight
                }px`
                content.style.setProperty('--content-height', contentHeight)

                let scrollTop = container.scrollTop ?? 0
                let scrollHeight = container.scrollHeight ?? 0
                let clientHeight = container.clientHeight ?? 0
                if (scrollTop + clientHeight >= scrollHeight - 1)
                    this.isOnBottom = true
                else {
                    this.isOnBottom = false
                }
            })
        },
        setHasScrollBar: function () {
            let scrollHeight,
                clientHeight = 0
            this.$nextTick(() => {
                const container = document.querySelector(
                    '.sync-modal .modal-area'
                )
                scrollHeight = container.scrollHeight
                clientHeight = container.clientHeight
                this.isShowScroll = scrollHeight > clientHeight
            })
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        selectList: function () {
            let status = _.cloneDeep(this.syncOptions[`${this.page}`])
            if (this.dataStatus !== null) {
                Object.keys(this.dataStatus).forEach((key) => {
                    if (this.dataStatus[key] === null) {
                        status.forEach((data) => {
                            if (data.value === key) {
                                data.label += '(尚無資料)'
                                data.isDisabled = true
                            }
                        })
                    }
                })
            }
            return status
        }
    },
    watch: {
        modelValue: {
            handler() {
                const defaultDoc = 'mainContract'
                if (this.modelValue) {
                    this.docName = defaultDoc ?? ''
                    window.addEventListener('resize', this.setOnBottom, true)
                    window.addEventListener('scroll', this.setOnBottom, true)
                } else {
                    this.docName = ''
                    window.removeEventListener('resize', this.setOnBottom, true)
                    window.removeEventListener('scroll', this.setOnBottom, true)
                }
            },
            immediate: true
        },
        docName: function () {
            this.$setGaEvent(`${this.docName}Sync`, 'selected-ModalOption', {
                isModal: true
            }) //GA
        }
    },
    data() {
        return {
            docName: '',
            syncOptions: syncOptions,
            syncDocConfig: config,
            isLoading: false,
            isOnBottom: false,
            isShowScroll: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            const erd = ElementResizeDetectorMaker()
            const syncCard = document.querySelector('.syncCard')
            if (syncCard) {
                erd.listenTo(syncCard, () => {
                    this.setHasScrollBar()
                    this.setOnBottom()
                })
                this.setHasScrollBar()
            }
        })
    },
    beforeUnmount() {
        const erd = ElementResizeDetectorMaker()
        if (document.querySelector('.syncCard'))
            erd.removeListener(document.querySelector('.syncCard'))
    }
}
</script>

<style lang="scss" scoped>
:deep(.modal-size) {
    position: relative;
    width: 780px;
    height: Min(calc(100vh - 60px), 650px);
    .input-container {
        margin-bottom: 0;
    }
    @media screen and (max-width: 576px) {
        height: calc(100vh - 60px);
    }
}

:deep(.modal-content) {
    min-height: var(--content-height);
    .nodata-container {
        margin: 0 0;
        padding: 100px 0 0 0;
    }
    @media screen and (max-width: 576px) {
        .nodata-container {
            padding-top: 30px;
        }

        .loading-container {
            padding-top: 30px;
        }
    }
}

//下拉選單和資訊
.info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;

    .info-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .info {
            display: block;
            color: $sixth-black;
            margin-right: 10px;
            font-size: 14px;
        }
        .title {
            color: $placeholder-black;
        }
        @media screen and (max-width: 576px) {
            div {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    :deep(.input-container) {
        min-width: 200px;
        .option-container {
            max-height: Min(calc(100vh - 250px), 500px);
        }
        @media screen and (max-width: 576px) {
            width: 100%;
            margin-top: 10px;
            .option-container {
                max-height: calc(100vh - 380px);
            }
        }
    }

    @media screen and (max-width: 576px) {
        display: block;
    }
}

.modal-bottom {
    .more {
        position: absolute;
        z-index: -1;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: $secondary-grey;
        p {
            width: fit-content;
            margin: 0 auto;
            span {
                display: inline-block;
                animation: arrow 1s infinite linear;
            }
        }
        @keyframes arrow {
            0% {
                transform: translateY(0);
            }
            25% {
                transform: translateY(-2px);
            }
            50% {
                transform: translateY(0);
            }
            75% {
                transform: translateY(2px);
            }
            100% {
                transform: translateY(0);
            }
        }
    }
}

//手機打橫modal調整
:deep(.modal-area) {
    @media screen and (max-height: 576px) {
        padding: 20px;
        .modal-title {
            margin-top: -20px;
            top: -20px;
            padding: 20px 10px 20px 10px;
        }
        .modal-bottom {
            bottom: -20px;
            margin-bottom: -20px;
            padding: 20px 10px 20px 10px;
        }

        .nodata-container {
            padding: 15px 0 0 0;
            .empty-message,
            .loading-message {
                margin-top: 5px;
            }
        }
    }
}
</style>
