<template>
    <div>
        <ViewTitle :title="`磊山e管家後台 | 活動進度查詢`" />
        <CtwEventFilter
            v-model:filterLogProp="filterLog"
            v-model:filterPayloadProp="filterPayload"
            @show-data-table="getListData"
        />
        <Table
            :isLoading="isTableLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isPagination="true"
            :isFocusRow="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel']"
            class="table"
            :isFixedHeader="true"
            v-model:sortDefault="sortValue"
        >
            <template v-slot:registerTaskStatus="{ isHeader, row }">
                <div v-if="!isHeader">
                    <span
                        :class="{
                            unfinished: row.registerTaskStatus === '未完成'
                        }"
                    >
                        {{ row.registerTaskStatus }}
                    </span>
                </div>
            </template>
            <template v-slot:uploadTaskStatus="{ isHeader, row }">
                <div v-if="!isHeader">
                    <span
                        :class="{
                            unfinished: row.uploadTaskStatus === '未完成'
                        }"
                    >
                        {{ row.uploadTaskStatus }}
                    </span>
                </div>
            </template>
        </Table>
    </div>
</template>
<script>
import _ from 'lodash'
import dayjs from 'dayjs'

import CtwEventFilter from '@/containers/ctw/CtwEventFilter.vue'
import Table from '@/components/Table.vue'
import ViewTitle from '@/components/ViewTitle.vue'

import { ctwEventColumns } from '@/assets/javascripts/ctw/setting'
import { getEventStatusListAPI } from '@/assets/javascripts/api'

export default {
    name: 'CtwEvent',
    components: {
        CtwEventFilter,
        Table,
        ViewTitle
    },
    methods: {
        /**
         * 取得list data
         */
        getListData: async function () {
            if (this.filterPayload.manCodes.length <= 0) return
            try {
                this.isTableLoading = true
                this.$showLoading()
                const response = await this.$getResponse(
                    this.filterPayload,
                    { apiAlias: 'list', apiName: '此報表' },
                    getEventStatusListAPI
                )
                this.listData = response.data.taskStatuses
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        }
    },
    computed: {
        /**
         * 顯示table data row
         */
        tableShowData: function () {
            const handleData = _.cloneDeep(this.listData)
            handleData.forEach((row) => {
                row.registerTaskStatus = row.registerTaskStatus
                    ? '已完成'
                    : '未完成'
                row.uploadTaskStatus = row.uploadTaskStatus
                    ? '已完成'
                    : '未完成'
            })
            return handleData
        },
        /**
         * excel查詢條件資料設置
         */
        excelFilterLog: function () {
            const log = _.cloneDeep(this.filterLog)
            delete log.manCodes
            delete log.titleType
            return log
        },
        /**
         * compose excelConfig
         */
        excelConfig: function () {
            const today = dayjs().format('YYYYMMDD')
            const account = this.filterLog?.manCodes?.label
            const fileName = `${today}_${account}_活動進度查詢`
            return {
                fileName: fileName,
                filters: this.excelFilterLog
            }
        }
    },
    data() {
        return {
            isTableLoading: false,
            tableColumnConfig: ctwEventColumns,
            listData: [],
            filterPayload: {
                manCodes: [],
                eventId: 0,
                userName: '',
                registerDate: ''
            },
            filterLog: {},
            sortValue: { sort: 'desc', sortBy: 'registerDate' }
        }
    }
}
</script>

<style lang="scss" scoped>
.unfinished {
    color: $fourth-red;
}
</style>
