<template>
    <div>
        <ViewTitle :title="`磊山e管家後台 | 客戶查詢`" />
        <CtwCustomerFilter
            v-model:filterLogProp="filterLog"
            v-model:filterPayloadProp="filterPayload"
            @show-data-table="getListData"
        ></CtwCustomerFilter>
        <div class="customer-total" v-show="customerAmount">
            於磊山e管家綁定您為專屬業務的總客戶數：{{
                $numberWithComma(customerAmount)
            }}人
        </div>
        <Table
            :isLoading="isTableLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isPagination="true"
            :isFocusRow="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel']"
            class="table"
            :isFixedHeader="true"
            v-model:sortDefault="sortValue"
        >
            <template v-slot:insLink="{ isHeader, row }">
                <template v-if="!isHeader">
                    <div
                        :class="
                            $isMobile()
                                ? 'mobile-insurance-btn'
                                : 'detail-link-icon'
                        "
                        @click.stop="goSearchInsurance(row)"
                    />
                </template>
            </template>
            <template v-slot:userAge="{ isHeader, row }">
                <template v-if="!isHeader">
                    <span>{{ row.userAge }}歲</span>
                </template>
            </template>
        </Table>
    </div>
</template>
<script>
import _ from 'lodash'
import dayjs from 'dayjs'

import CtwCustomerFilter from '@/containers/ctw/CtwCustomerFilter.vue'
import Table from '@/components/Table.vue'
import ViewTitle from '@/components/ViewTitle.vue'

import { ctwCustomerColumns } from '@/assets/javascripts/ctw/setting'
import { getCTWCustomerListAPI } from '@/assets/javascripts/api'

export default {
    name: 'CtwCustomer',
    components: {
        CtwCustomerFilter,
        Table,
        ViewTitle
    },
    methods: {
        /**
         * 取得list data
         */
        getListData: async function () {
            if (this.filterPayload.manCodes.length <= 0) return
            try {
                this.isTableLoading = true
                this.$showLoading()
                const payload = { ...this.filterPayload }
                delete payload.titleType
                const { data } = await this.$getResponse(
                    payload,
                    { apiAlias: 'list', apiName: '此報表' },
                    getCTWCustomerListAPI
                )
                this.listData = data.customer
                this.customerAmount = data.customerAmount
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        /**
         * 保單link跳轉
         * @param rowData customer list row data
         */
        goSearchInsurance: function (rowData) {
            let insurancePayload = _.cloneDeep(this.filterPayload)
            delete insurancePayload.registerDate
            delete insurancePayload.titleType
            delete insurancePayload.manCodes
            insurancePayload.userName = rowData.userName
            this.$crossPageFilter('CtwUpload', insurancePayload)
        }
    },
    computed: {
        /**
         * 顯示table data row
         */
        tableShowData: function () {
            const handleData = _.cloneDeep(this.listData)
            return handleData
        },
        /**
         * excel查詢條件資料設置
         */
        excelFilterLog: function () {
            const log = _.cloneDeep(this.filterLog)
            delete log.manCodes
            delete log.titleType
            delete log.insLink
            return log
        },
        /**
         * compose excelConfig
         */
        excelConfig: function () {
            const today = dayjs().format('YYYYMMDD')
            const account = this.filterLog?.manCodes?.label
            const fileName = `${today}_${account}_客戶查詢`
            return {
                fileName: fileName,
                filters: this.excelFilterLog
            }
        }
    },
    data() {
        return {
            isTableLoading: false,
            tableColumnConfig: ctwCustomerColumns,
            listData: [],
            customerAmount: 0,
            filterPayload: {
                manCodes: [],
                userName: '',
                registerDate: ''
            },
            filterLog: {},
            sortValue: { sort: 'desc', sortBy: 'registerDate' }
        }
    }
}
</script>

<style lang="scss" scoped>
.customer-total {
    margin: 20px 0;
    color: $sixth-black;
    font-size: 14px;
}

:deep(.ins-link) {
    position: relative;
    .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
</style>
